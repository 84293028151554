import React, { useEffect, useState } from 'react'
import "../CSS/Navbar.css"
import "../CSS/color.css"
import "../CSS/counter.css"
import "../CSS/Home.css"
// import "../CSS/ACCAProgram.css"
import "react-multi-carousel/lib/styles.css";
// import { useMediaQuery } from 'react-responsive';
// import Slider1 from '../Components/Slider'
// import photo1 from "../Photos/image1.jpg"
// import photo2 from "../Photos/image2.jpg"
// import photo3 from "../Photos/image3.jpg"
// import photo4 from "../Photos/image4.jpg"
// import image3 from "../Photos/image3.yjpg"
import { backendURL } from '../constant'
import Sectiontitle1 from "../Photos/section-title-shape-1.png"
import Sectiontitle2 from "../Photos/section-title-shape-2.png"
import partner1 from "../Photos/partner1.png"
import partner2 from "../Photos/partner2.png"
import partner3 from "../Photos/partner3.png"
import partner4 from "../Photos/partner4.png"
import partner5 from "../Photos/partner5.png"
import partner6 from "../Photos/partner6.png"
import explore2 from "../Photos/explore2.jpg"
import explore1 from "../Photos/explore1.jpeg"
import megaphone from "../Photos/megaphone.png"
import quality from "../Photos/quality.png"
import trust from "../Photos/trust.png"
import partnership from "../Photos/partnership.png"
import motivation from "../Photos/motivation.png"
import envelope from "../Photos/envelope.png"
import enjoy from "../Photos/enjoy.png"
import goal from "../Photos/goal.png"
import admission from "../Photos/admission.png"

import Counter from '../Components/counter'
import MultiItemCarousel from '../Components/multiCarousel'
// import Videogallery2 from '../Components/videogallery2'
import ObjectiveSection from '../Components/ObjectiveSection'
import Navbar from '../Components/navbar'
import { Link } from 'react-router-dom'
import dollarhand from "../Photos/dollarhand.png"
import Homeabout from "../Photos/Homeabout.png"
// import Navbar from '../Components/navbar'
import Popup from '../Components/Popup';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import logo from "../Photos/Skanslogo1.png"
import ReactPaginate from 'react-paginate'
import axios from 'axios'
import CollegeTour from './CollegeTour'

function Home() {




  const currentYear = new Date().getFullYear();
  const [announcement, setAnnouncment] = useState([])
  const [currentPage, setCurrentPage] = useState(0);
  const universitiesPerPage = 3;




  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [screendetail, setScreenDetail] = useState(null);
  const [highlight, setHighlight] = useState(null);
  const [counterback, setCounterback] = useState(null);



  const [highachiever, setHighAchiever] = useState(null);

  let [loading, setLoading] = useState(true);

  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);




  const togglePopup3 = () => {
    setIsOpen1(!isOpen1);
  }
  const togglePopup4 = () => {
    setIsOpen2(!isOpen2);
  }
  const togglePopup5 = () => {
    setIsOpen3(!isOpen3);
  }
  const togglePopup6 = () => {
    setIsOpen4(!isOpen4);
  }

  const togglePopup7 = () => {
    setIsOpen5(!isOpen5);
  }

  const togglePopup8 = () => {
    setIsOpen6(!isOpen6);
  }

  const togglePopup9 = () => {
    setIsOpen7(!isOpen7);
  }



  const callProfile = async () => {
    try {
      setLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeScreenImage`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("screenimage", result);

          const data = JSON.parse(result);
          console.log(data);
          setScreenDetail(data);
          setLoading(false);

        })
        .catch((error) => console.error(error));

    } catch (error) {
      console.error(error);
    }
  };




  const callHighlight = async () => {
    try {
      setLoading(true);

      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");



      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeHighlight`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("highlight", result);

          const data = JSON.parse(result);
          console.log(data);
          setHighlight(data);
          setLoading(false);

        })
        .catch((error) => console.error(error));
      // const res = await fetch("/getHomeHighlight", {
      //   method: "GET",
      //   headers: {
      //     Accept: "application/json",
      //     "Content-Type": "application/json",
      //   },
      //   credentials: "include",
      // });

      // if (res.status === 200) {
      //   const data = await res.json();
      //   console.log(data);
      //   if (data.length > 0) {

      //   }
      //   setHighlight(data);
      //   setLoading(false);
      // } else {
      //   const error = new Error(res.statusText);
      //   throw error;
      // }
    } catch (err) {
      console.log(err);

    }
  };


  const callAnnouncement = async () => {
    try {
      setLoading(true);
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      const requestOptions = {
        method: "GET",
        headers: myHeaders,

        redirect: "follow"
      };

      fetch(`${backendURL}/getHomeAnnouncement`, requestOptions)
        .then((response) => response.text())
        .then(async (result) => {
          console.log("announcement", result);

          const data = JSON.parse(result);
          console.log(data);
          setAnnouncment(data);
          setLoading(false);

        })
        .catch((error) => console.error(error));

    } catch (err) {
      console.log(err);

    }
  };







  useEffect(() => {

    // const myHeaders = new Headers();
    // myHeaders.append("Content-Type", "application/json");



    // const requestOptions = {
    //   method: "GET",
    //   headers: myHeaders,

    //   redirect: "follow"
    // };

    // fetch("https://backend.skans.pk/getHomeScreenImage", requestOptions)
    //   .then((response) => response.text())
    //   .then(async(result) => {
    //     console.log("hsgfhsgfh",result);

    //       const data =JSON.parse(result);
    //       console.log(data);
    //       setScreenDetail(data);
    //       setLoading(false);

    //   })
    //   .catch((error) => console.error(error));
    callProfile();
    callHighlight();
    callAnnouncement();

    const fetchDataInterval = setInterval(() => {
      callProfile();
      callHighlight();
      callAnnouncement();
    }, 1000); // Adjust the interval as needed

    return () => {
      // Clear the interval when the component unmounts
      clearInterval(fetchDataInterval);
    };

  }, []);
  const picurl = `${backendURL}/public/uploads/`;
  const indexOfLastUniversity = (currentPage + 1) * universitiesPerPage;
  const indexOfFirstUniversity = indexOfLastUniversity - universitiesPerPage;
  const currentAnnouncment = announcement.slice(indexOfFirstUniversity, indexOfLastUniversity);




  return (
    <>
      <Navbar />
      {/* <Navbar /> */}




      <div className=''>

        {/* <div className='d-flex bg-lightgrey hv-100 text-dark fs-1'>
        
          <div className={`col-12`}>
            <div className='image-layer '>


              <div className={`image image-${currentImageIndex}`} style={{ backgroundImage: `url(${backgroundImages[currentImageIndex]})` }} ></div>
             
            </div>

          </div>
        </div> */}


        {/* <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 3000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"

        >

          {
            Array.isArray(screendetail) && screendetail.length > 0
              ? screendetail.map((photo, id) => (
               
                <SwiperSlide><img src={picurl + photo.photos} class=" img-fluid " alt="..." /></SwiperSlide>

              
              )) : (<div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>)
          }



        </Swiper> */}

        <div className='vh-75 d-flex col-12 bg-primary flex-wrap'>
          <div className='col-md-6 col-12 bgfronthome1 imgfronthome1 border  border-left-0 border-bottom-0'>
            <div className='filterhomeup container-fluid  ps-md-5 px-0 text-light'>
              <div className='d-flex flex-column align-items-center justify-content-center'>

                <div className='d-flex align-items-center justify-content-center'>
                  <div className='col-12 text-center link'>

                    <Link to="/CollegeVirtualTour" class=" link fw-bold text-uppercase pt-lg-2 pt-0 mb-0  fs-2">
                      <span class="mask">
                        <div class="link-container text-center">
                          <span class="fs-1 link-title1 title text-center">Colleges</span>
                          <span class="fs-1 link-title2 title text-center">Colleges</span>
                        </div>
                      </span>

                    </Link>
                    {/* <Link className='fw-bold text-uppercase pt-4 mb-0  fs-2 py-5' to="/CollegeVirtualTour">Colleges / Institutes</Link> */}

                  </div>
                  {/* <span className='fw-bold fs-2 mb-5'> onClick={togglePopup8}
                                __________________________
                            </span> */}

                  <div className='col-2 pb-4'>
                    <div class="arrow mt-3 mt-sm-0">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>

                  {/* <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>JUNIOR CHAKRI ROAD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3> */}

                  {/* <div className='fw-bold fs-2 text-center'>
                                __________________________
                            </div> */}
                </div>

              </div>

            </div>

          </div>
          <div className='col-md-6 col-12 bgfronthome2 imgfronthome1 border  border-right-0 border-bottom-0'>
            <div className='filterhomeup container-fluid  ps-md-5 px-0 text-light'>

              <div className='d-flex flex-column align-items-center justify-content-center'>

                <div className='d-flex align-items-center justify-content-center'>
                  <div className='col-12 text-center'>
                    {/* <Link className='fw-bold text-uppercase pt-4 mb-0  fs-2 py-5' to="/SchoolVirtualTour">SCHOOLS </Link> */}
                    <Link to="/SchoolVirtualTour" class=" link fw-bold text-uppercase  pt-lg-2 pt-0 mb-0  fs-2">
                      <span class="mask">
                        <div class="link-container">
                          <span class="fs-1 link-title1 title text-center">Schools</span>
                          <span class=" fs-1 link-title2 title text-center">Schools</span>
                        </div>
                      </span>

                    </Link>
                  </div>
                  {/* <span className='fw-bold fs-2 mb-5'>
                __________________________onClick={togglePopup9}
            </span> */}

                  <div className='col-2'>
                    <div class="arrow ">
                      <span></span>
                      <span></span>
                      <span></span>
                    </div>
                  </div>

                  {/* <h3 className='fw-bold text-uppercase pt-4 mb-0 d-md-none d-block'><i class="bi bi-stars fs-1 fw-bold pe-md-3 pe-1"></i>JUNIOR CHAKRI ROAD CAMPUS<i class="bi bi-stars fs-1 fw-bold ps-md-3 ps-1"></i></h3> */}

                  {/* <div className='fw-bold fs-2 text-center'>
                __________________________
            </div> */}
                </div>

              </div>

            </div>
          </div>
        </div>



        {/* {isOpen6 && <Popup
          content={<>
            <div class="modal-header">
              <h5 class="modal-title text-uppercase fw-bold px-4 py-3 " id="ProgramOfferLabel">College Campuses</h5>
            
            </div>
            <div className='p-3 bgdarkblue'>
              
              <div className='d-flex flex-column text-center'>
                <Link className='btn feature-one__button-color my-3' to="/F8Campus">SKANS F8 Campus</Link>
                <Link className='btn feature-one__button-color' to="/SatelliteTownCampus">SKANS Sattelite Town Campus</Link>
                <Link className='btn feature-one__button-color my-3' to="/RARCAmpus">SKANS Raja Akram Road, Saddar Campus</Link>
                <Link className='btn feature-one__button-color' to="/ACCAPeshawarRoadCampus"> SKANS ACCA Peshawar Road Campus</Link>



              </div>
            </div>


          </>}
          handleClose={togglePopup8}

        />} */}



        {/* {isOpen7 && <Popup
          content={<>
            <div class="modal-header" >
              <h5 class="modal-title text-uppercase fw-bold px-4 py-3 " id="ProgramOfferLabel">School Campuses</h5>
             
            </div>
            <div className='p-3 bgdarkblue'>
              <div className='d-flex flex-column text-center'>
                <Link className='btn feature-one__button-color mb-1' to="/JuniorHarleyCampus">Harley Street Campus</Link>

                <Link className='btn feature-one__button-color ' to="/JuniorChakriRoad">Junior Chakri Road Campus </Link>
                <Link className='btn feature-one__button-color my-1' to="/JuniorWestridgeCampus">Junior Westridge Campus </Link>
                <Link className='btn feature-one__button-color ' to="/SeniorWestridgeCampus">Senior Westridge Campus</Link>
                <Link className='btn feature-one__button-color my-1' to="/GolraMorCampus">Golra Mor Campus</Link>
                <Link className='btn feature-one__button-color ' to="/PeshawarRoadCampus">Peshawar Road Campus</Link>
                <Link className='btn feature-one__button-color my-1' to="/PWDCampus">SKANS PWD Campus</Link>
                <Link className='btn feature-one__button-color ' to="/AirportCampus">SKANS AECHS Campus</Link>
              </div>
            </div>

          </>}
          handleClose={togglePopup9}

        />} */}





        <div className=' d-flex bg-lightgrey'>
          <div className='col-lg-2 col-3 skansRed text-white fw-bold text-center'>
            <h4 className='p-md-3 p-1 blink mb-0  d-md-block d-none'>𝙐𝙋𝘿𝘼𝙏𝙀𝙎</h4>
            <h6 className='p-md-3 p-1 blink mb-0 d-md-none d-block '>𝙐𝙋𝘿𝘼𝙏𝙀𝙎</h6>

          </div>
          <div className='col-lg-10 col-9 d-flex align-items-center bgdarkblue d-md-block d-none text-center'>
            {
              highlight ? highlight.map((high, id) => (
                <marquee className="p-md-2 fs-4 text-white ">{high.highlight}</marquee>


              )) : (<div class="spinner-border text-primary" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>)
            }


          </div>
          <div className='col-lg-10 col-9 d-flex align-items-center bgdarkblue d-md-none d-block'>
            {
              highlight ? highlight.map((high, id) => (
                <marquee className=" fs-6 text-white ">{high.highlight}</marquee>


              )) : (<p>Loading...</p>)





            }

          </div>

        </div>


      </div>










      <div className='py-5 border border-top border-bottom bgcounter overflow-hidden'>
        <div className='container-md container-fluid mb-5 fs-2'>
          <span className='borderdash text-warning p-2'>
            <span className='text-dark p-md-5 p-0'>Statistics <span className='fw-bold fontcolordark'>About <span className='fs-2' style={{ fontFamily: "serif" }}>SKANS</span></span>
            </span>
          </span>
        </div>
        <Counter />
      </div>


      {/* ADMISSSION AND FEE STRUCTURE */}
      {/* <div className='mt-5 overflow-hidden'>
        <div class="d-flex flex-wrap justify-content-around my-5">
          <div class=" col-lg-5 col-10 rounded-5 shadow-lg mt-5 mt-lg-0 pb-3" data-aos="fade-right">
            <div class="card border border-0 position-relative feature-one__single-inner">
              <div className='icon-insurance feature-one__icon '>
                <span>
              
                  <img width="80" height="80" src={admission} alt="admission" />
                </span>

              </div>
              <div className='feature-one__count'>
              </div>
              <div class="card-body  pt-5  mt-5">
                <h5 class="card-title fs-3 pt-5 fw-bold overflow-hidden">Admissions Open</h5>
                <p class="card-text">"Enroll now for {currentYear} admissions and be part of a vibrant learning community dedicated to fostering growth and achievement. Your next big adventure starts here!"</p>
                <span className='d-flex justify-content-center'>
                  <Link className="btn feature-one__button-color px-5" onClick={togglePopup3}>Apply Now! </Link>




                </span>
              </div>
            </div>
          </div>


          {isOpen1 && <Popup
            content={<>
              <div class="modal-header ">
                <h5 class="modal-title text-uppercase fw-bold px-4 py-3 " id="ProgramOfferLabel">Admissions open</h5>
                
              </div>
              <div className='p-3 bgdarkblue'>
          
                <div className='d-flex flex-column text-center'>
                  <Link className='btn feature-one__button-color my-3' to='/AdmissionSchool' >SKANS SCHOOLS</Link>
                  <Link className='btn feature-one__button-color' to="/CollegeAdmission">SKANS COLLEGES</Link>
                </div>
              </div>


            </>}
            handleClose={togglePopup3}



          />}




          <div class=" col-lg-5 col-10 rounded-5 shadow-lg  mt-5 mt-lg-0" data-aos="fade-left">
            <div class="card border border-0 position-relative feature-one__single-inner">
              <div className='icon-insurance feature-one__icon icon-insurance'>
                <span>
                 
                  <img className='img-fluid' width={"50%"} src={dollarhand}></img>
                
                </span>
              </div>
              <div className='feature-two__count'>
              </div>
              <div class="card-body pt-5 mt-5">
                <h5 class="card-title pt-5 fs-3 fw-bold overflow-hidden">Fee Structure</h5>
                <p class="card-text">"Explore transparent and competitive fee structures for our {currentYear} programs at SKANS Schools of Accountancy. We believe in providing value-driven education accessible to all aspiring professionals."</p>
                <span className='d-flex justify-content-center'>
                  <Link className="btn feature-one__button-color px-5" onClick={togglePopup4}>View Fee Structure</Link>




                  {isOpen2 && <Popup
                    content={<>
                      <div class="modal-header ">
                        <h5 class="modal-title text-uppercase fw-bold px-4 py-3 " id="ProgramOfferLabel">FEE STRUCTURE</h5>
                       
                      </div>
                      <div className='p-3 bgdarkblue'>
                       
                        <div className='d-flex flex-column text-center'>
                          <Link className='btn feature-one__button-color my-3' to='/CAFeeStructure' >CA Fee Structure</Link>
                          <Link className='btn feature-one__button-color' to="/ACCAFeeStructure">ACCA Fee Structure</Link>
                        </div>
                      </div>


                    </>}
                    handleClose={togglePopup4}

                  />}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div> */}




      {/* ABOUT US */}
      {/* <div className='container  d-flex flex-wrap mt-5 overflow-hidden'>
        <div className='about-one container col-lg-6 col-12  pt-0  d-flex justify-content-cenetr ' data-aos="fade-up-right">
       
          <img className='rounded-5 img-fluid' src={Homeabout} width={"100%"} height={"200%"}></img>

        </div>
        <div className='col-lg-6 col-12 ps-lg-5 ps-0  mt-lg-0 mt-5 ' data-aos="fade-up-left">
          <div className='d-flex justify-content-center align-items-center fw-bold pb-3' >
            <img height={"60%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
            <span className='fs-4'>ABOUT <span className='fs-3 ps-1 fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span></span>&nbsp; &nbsp;
            <img height={"60%"} src={Sectiontitle2}></img>
          </div>
          <div style={{ textAlign: "justify" }}>
            <p className='lh-lg'><span className=' fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span> is the acronym of an institution, which stands for Skills, Knowledge and Application Nurtured through Student-managed participative learning approach. It aims to be one of the leading business schools in the field of Professional Accountancy Education, catering to the students needs in a proactive manner. It has developed an expertise in the realm of CA (Pak), ACCA (UK), CIMA (UK), CAT (UK), CISA(USA).</p>

          </div>
        </div>

      </div> */}


      <div className='mt-5 bg-lightgrey cta_bg overflow-hidden'>
        <div className='d-flex align-items-center container' data-aos="zoom-in">
          <div className='b'>

          </div>
          <div className='my-5 d-flex align-items-center justify-content-center border p-3 bgdarkblue rounded' data-aos="zoom-in">
            <img height={"10%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
            <h3 className='text-light fw-bold mb-0'>Our Core Values</h3>&nbsp;&nbsp;
            <img height={"10%"} src={Sectiontitle2}></img>
          </div>
          <div className='b'>
          </div>
        </div>
        <ul className='list-unstyled d-flex flex-wrap container justify-content-around fs-6 lh-lg'>
          <li className=' col-lg-3 col-md-5 col-10 p-5 blackhover mb-4' data-aos="fade-right">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div className=''>
                <img src={quality} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold'>
                Quality, Excellence & Achievement
              </div>
            </div>
          </li>
          <li className=' col-lg-3 col-md-5 col-10  p-5 blackhover ms-lg-5 ms-md-3 ms-0 me-lg-5 me-0 mb-4' data-aos="zoom-in">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div>
                <img src={trust} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold '>
                Honesty,Respect & Integrity
              </div>
            </div>
          </li>
          <li className='  col-lg-3 col-md-5 col-10  p-5 blackhover mb-4' data-aos="fade-left">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div>
                <img src={partnership} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold '>
                Commitment & Responsibility
              </div>
            </div>
          </li>


          {/* </ul>
        <ul className='list-unstyled d-flex flex-wrap container justify-content-around fs-6 lh-lg pb-4'> */}
          <li className='  col-lg-3 col-md-5 col-10  p-5 blackhover mb-4' data-aos="fade-right">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div>
                <img src={motivation} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold'>
                Motivate, value & allow everyone to Thrive
              </div>
            </div>
          </li>
          <li className='col-lg-3 col-md-5 col-10  p-5 blackhover ms-lg-5 ms-md-3 ms-0 me-lg-5 me-0 mb-4' data-aos="zoom-in">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div>
                <img src={goal} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold '>
                Challenge & Reward
              </div>
            </div>
          </li>
          <li className='  col-lg-3 col-md-5 col-10  p-5 blackhover mb-4' data-aos="fade-left">
            <div className='d-flex flex-column align-items-center justify-content-center text-center'>
              <div>
                <img src={enjoy} width={"60%"}></img>
              </div>

              <div className='px-3 fw-bold '>
                Fun & Enjoyment
              </div>
            </div>
          </li>
        </ul>

      </div>




      <div className='container-fluid d-flex d-block flex-wrap my-1 overflow-hidden'>
        {/* <div className='col-lg-9 col-12' data-aos="fade-right">
          <ObjectiveSection />
        </div> */}
        <div className='d-flex flex-column bg-lightgrey rounded-3 container p-2 cta_bg' data-aos="fade-left">
          <div className='text-center d-flex align-items-center'>
            <div className='col-3 d-flex align-items-center'>
              <img src={megaphone} width={"30%"}></img>
            </div>
            <div className='col-6'>
              <h5 className='textdarkblue fw-bold py-3 text-center'>General Announcement</h5>
            </div>

          </div>

          <div className=' border border-start-0 border-end-0 my-0 py-3'>
            {
              currentAnnouncment && currentAnnouncment.length > 0 ? currentAnnouncment.map((high, id) => (
                <div key={id} class="sample mb-3" data-aos="fade-up"
                  data-aos-anchor-placement="top-bottom">
                  <div class="ribbon down" style={{ color: "#fd9c2e" }}>
                    <div class="content">
                      {high.date}
                    </div>
                  </div>
                  <div className='p-5 px-3'>
                    <h4 className='fontcolordark pt-4 fw-bold'>{high.title}</h4>
                    <p className='text-truncate'>{high.description}</p>
                    <Link className='text-primary' to={`/AnnouncementDetail/${high.id}`}>Read More</Link>
                  </div>

                </div>

              )) : (<p>Not Found</p>)





            }




          </div>

          <ReactPaginate
            pageCount={Math.ceil(announcement.length / universitiesPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={({ selected }) => setCurrentPage(selected)}
            containerClassName="pagination justify-content-center"
            pageClassName="page-item"
            pageLinkClassName="page-link"
            activeClassName="active"
            previousClassName="page-item"
            previousLinkClassName="page-link"
            nextClassName="page-item"
            nextLinkClassName="page-link"
            previousLabel={<i className="bi bi-chevron-left"></i>}
            nextLabel={<i className="bi bi-chevron-right"></i>}
          />

        </div>


      </div>









      <div className='my-5 pt-5 bg-lightgrey overflow-hidden' data-aos="fade-up"
        data-aos-anchor-placement="top-bottom">
        <div className='container pb-2'>
          <div className='d-flex flex-wrap justify-content-between align-items-center '>
            <div className='d-flex justify-content-lg-start  justify-content-center align-items-center fw-bold col-lg-6 col-12 fw-bold '>
              <img height={"50%"} src={Sectiontitle1}></img>&nbsp;&nbsp;
              <span className='fs-4 text-center'><span className='fs-3 fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span> HIGH ACHIEVERS</span>&nbsp; &nbsp;
              <img height={"50%"} src={Sectiontitle2}></img>

            </div>
            <div className='col-lg-6 col-12 lh-lg text-justify'>
              "At SKANS School of Accountancy, we take immense pride in our accomplished achievers. With a proven track record of excellence, our graduates consistently secure top positions in professional accounting examinations. Our commitment to quality education and unwavering support from dedicated faculty ensures that every student becomes a high-achieving professional, ready to excel in the accounting world."
            </div>


          </div>
        </div>









        <div className='d-flex justify-content-around text-dark mb-5 overflow-hidden z-1'>
          {/* <div class="card" style={{width: "18rem"}}>
  <img class="card-img-top" src="..." alt="Card image cap"/>
  <div class="card-body">
    <h5 class="card-title">Card title</h5>
    <p class="card-text">Some quick example text to build on the card title and make up the bulk of the card's content.</p>
    <a href="#" class="btn btn-primary">Go somewhere</a>
  </div>
</div> */}

          <MultiItemCarousel />

        </div>
      </div>




      <div className=' overflow-hidden'>
        <div className='pb-5 text-center mt-5' data-aos="flip-left"
          data-aos-easing="ease-out-cubic" data-aos-duration="1500">
          <h1 className='fs-1'>We're not the only ones <span className='fw-bold'>excited</span> about <span className=' fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span></h1>
          <p className='fs-3 text-primary'>25,000 + Students In 100 + Countries Are Part Of <span className=' fontcolordark fw-bold' style={{ fontFamily: "serif" }}>SKANS</span>.</p>
        </div>
        <div class="tech-slideshow">
          <div class="mover-1">
            <img src={partner1} />
            <img src={partner2} />
            <img src={partner3} />
            <img src={partner4} />
            <img src={partner5} />
            <img src={partner6} />
            <img src={partner1} />
            <img src={partner2} />
            <img src={partner3} />
            <img src={partner4} />
            <img src={partner5} />
            <img src={partner6} />
            <img src={partner1} />
            <img src={partner2} />
            <img src={partner3} />
            <img src={partner4} />
            <img src={partner5} />
            <img src={partner6} />
            <img src={partner1} />
            <img src={partner2} />
            <img src={partner3} />
            <img src={partner4} />
            <img src={partner5} />
            <img src={partner6} />
            <img src={partner1} />
            <img src={partner2} />
            <img src={partner3} />
            <img src={partner4} />
            <img src={partner5} />
            <img src={partner6} />

          </div>

        </div>
      </div>




      {/* <div className=''>
        <h1 className='text-center fw-bold  bgdarkblue text-white py-3 mx-lg-5 rounded-4'>Explore SKANS</h1>

        <div className='d-flex flex-wrap justify-content-around container'>


          <div className='col-lg-5 col-12 mt-lg-0 mt-5 d-flex justify-content-around'>
            <div class="card border border-0 shadow-lg bgdarkblue text-light rounded-5" style={{ width: "30rem" }}>
              <div class="card-body text-center py-5">
                <div className='borderdash text-warning '>
                  <h2 class="card-title fw-bold overflow-hidden text-light p-2">Explore SKANS Schools</h2>
                </div>

                <p class="card-text text-justify pt-4">Welcome to SKANS School of Accountancy, the leading institute for accounting education in Pakistan. For over 25 years, we have nurtured students who now hold esteemed positions in the corporate world. Our commitment is to provide quality education and cultivate a global perspective, fostering an inclusive community where every student can thrive and succeed.</p>

              </div>
              <div className='position-relative text-center'>
                <img class="card-img-bottom shadow-lg" src={explore2} alt="Card image cap" />
                <div className='position-absolute bottom-0 start-50 translate-middle'>
                  <button href="#" class="p-2 px-5 border-0 rounded bgdarkblue text-white fw-bold">Explore Schools</button>
                </div>
              </div>



            </div>
          </div>

          <div className='col-lg-5 col-12 mt-lg-0 mt-5 d-flex justify-content-around'>
            <div class="card border border-0 shadow-lg bgdarkblue text-light rounded-5" style={{ width: "30rem" }}>
              <div class="card-body text-center py-5">
                <div className='borderdash text-warning '>
                  <h2 class="card-title fw-bold overflow-hidden text-light p-2">Explore SKANS Colleges</h2>
                </div>

                <p class="card-text text-justify pt-4">Explore SKANS School of Accountancy, the premier institution for accounting professional education in Pakistan, with a legacy of 25 years. Our students have achieved esteemed positions in the global corporate sector, thanks to our quality education and internationally-focused curriculum. Join our inclusive community, where personal growth and academic excellence are celebrated.</p>

              </div>
              <div className='position-relative text-center'>
                <img class="card-img-bottom shadow-lg" src={explore1} alt="Card image cap" />
                <div className='position-absolute bottom-0 start-50 translate-middle'>
                  <button href="#" class="p-2 px-5 border-0 rounded bgdarkblue text-white fw-bold">Explore Colleges</button>

                </div>
              </div>



            </div>
          </div>
        </div>
      </div> */}









      <footer>
        <div class="mt-5 pt-5 footer bgdarkblue text-light">
          <div class="container">
            <div class="row">
              <div class="col-lg-5 col-xs-12 about-company">
                <h5 className='overflow-hidden'><img className='img-fluid w-50' src={logo}></img></h5>
                <p class="pr-5 text-white-50 pt-3 text-justify"><span className=' fw-bold' style={{ fontFamily: "serif" }}>SKANS</span> Institute is dedicated to providing Skills, Knowledge, and Application through a Student-managed participative learning approach. We aspire to be a leading business school in Professional Accountancy Education, actively meeting the needs of our students.

                  Our expertise encompasses CA (Pak), ACCA (UK), CIMA (UK), CAT (UK), and CISA (USA).</p>
                {/* <p><a href="#"><i class="fa fa-facebook-square mr-1"></i></a><a href="#"><i class="fa fa-linkedin-square"></i></a></p> */}
              </div>
              <div class="col-lg-3 col-xs-12 links">
                <h4 class="mt-lg-0 mt-sm-3 overflow-hidden">Contact Information</h4>
                <ul class="m-0 p-0 list-unstyled">
                  <li>- <Link className='text-decoration-none text-white' to="/F8Campus">F8 Islamabad Campus</Link></li>
                  <li>- <Link className='text-decoration-none text-white' to="/SatelliteTownCampus">Satellite Town Campus</Link></li>
                  <li>- <Link className='text-decoration-none text-white' to="/RARCAmpus">RAR Campus</Link></li>

                </ul>
              </div>
              <div class="col-lg-4 col-xs-12 location mt-md-0 mt-3">
                <h4 class="mt-lg-0 mt-sm-4 overflow-hidden">Head Office Location</h4>
                <p>14A-Haroon Building F8, Markaz Islamabad</p>
                <p class="mb-0"><i class="fa fa-phone mr-3"></i>	(+92)-51-2287313</p>
                <p class="mb-0"><i class="fa fa-phone mr-3"></i>	(+92)-51-2287315</p>

                <p><i class="fa fa-envelope-o mr-3"></i>inquiry@skans.pk</p>
              </div>
            </div>
            <div class="row mt-5">
              <div class="col copyright text-center ">
                <p class=""><small class=" text-white-50">© {currentYear} SKANS. All Rights Reserved.</small></p>
              </div>
            </div>
          </div>
        </div>
      </footer>

    </>
  )
}

export default Home

